<template>
    <div class="transcriptList">

        <Header/>
        <div class="box">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>考试成绩</el-breadcrumb-item>
                </el-breadcrumb>

                <CategoryTitle title="考试成绩" desc="想要其他科目"></CategoryTitle>

            </div>
            <div class="content">

                <div class="count">
                    <div class="tab">
                        <div class="tab-item" v-for="(item,index) in tabs" :key="index" @click="onTabChange(index)">
                            <div>{{item.title}}</div>
                            <div :class="active===index?'line active':'line'"></div>
                        </div>

                    </div>
                    <div style="display: flex">
                    <div class="quest-count">
                        <div style="display: flex;align-items: center">
                            <div class="value">{{examCount||'0'}}</div>
                            <div v-if="active===0" class="right" @click="$router.push({path:'exam',query:{id:1}})">去模拟考试<i class="el-icon-arrow-right"></i></div>
                        </div>
                        <div class="desc">共{{examCount}}次{{tabs[active].title}}，合格{{passCount}}次</div>
                    </div>
                    <div class="rate">
                        <div style="display: flex;align-items: flex-end">
                            <div class="value">{{examAccuracyRate||'0%'}}</div>
                            <div style="margin-left: 5px">合格率</div>
                        </div>
                        <div class="desc">继续保持！超越{{examAccuracySurpassRate||'0%'}}的人</div>
                    </div>
                    </div>
                </div>

                <div class="table-title">
                    <div class="title">{{tabs[active].title}}成绩</div>

                </div>
                <div>
                    <el-table
                            :data="records"
                            stripe
                            size="mini"
                            style="width: 100%">
                        <el-table-column
                                prop="date"
                                label="日期"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="time"
                                label="考试时长"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="score"
                                label="分数"
                        >
                            <template slot-scope="scope">
                                <div :style="scope.row.result==='合格'?'color: #00C94C':'color:#FF3B30'">
                                    {{scope.row.score}}分
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="result"
                            label="结果">
                            <template slot-scope="scope">
                                <div :style="scope.row.result==='合格'?'color: #00C94C':'color:#FF3B30'">
                                    {{scope.row.result}}
                                </div>

                            </template>
                    </el-table-column>
                        <el-table-column
                                prop="address"
                                align="right"
                                label="">
                            <template slot-scope="scope">

                                <el-button @click="$router.push({path:'/result',query:{id:scope.row.sjId,rounds:scope.row.rounds}})" type="text" size="mini">查看成绩单<i class="el-icon-arrow-right"></i></el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <el-pagination
                            @current-change="handelChange"
                            :hide-on-single-page="true"
                            background
                            layout="total, prev, pager, next"
                            :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Footer/>

    </div>


</template>

<script>
    import Header from "../components/Header.vue"
    import CategoryTitle  from  '@/components/CategoryTitle'
    import Footer from '../components/Footer'
    import {GetExamination,GetExaminationList} from '@/api/text'
    export default {
        name: "TranscriptList",
        components:{
            Header,
            CategoryTitle,
            Footer
        },
        data(){
            return{
                tabs:[
                    {
                      type:'0',
                      title:'模拟考试'
                    },
                    {
                        type:'1',
                        title:'综合测试'
                    }
                ],
                active:0,
                type:'0',
                //考试次数
                examCount:0,
                //合格次数
                passCount:0,
                //正确率
                examAccuracyRate:'',
                //正确率超越人数率
                examAccuracySurpassRate:'',
                records:[],
                total:1,
                page:1,
                pageSize:10,
            }
        },
        created(){

            this.getExaminationData();
            this.getExaminationListData();

            //监听科目改变
            this.bus.$on(this.ON_CATEGORY_CHANGE,()=>{
                this.getExaminationData();
                this.getExaminationListData();
            })

        },
        methods:{

            //加载成绩统计数据
            getExaminationData(){
                GetExamination(this.type,this.$store.state.user.category.id).then(res=>{

                    this.examCount=res.data.total;
                    this.passCount=res.data.qualified
                    this.examAccuracyRate=res.data.passRate
                    this.examAccuracySurpassRate=res.data.exceed

                })
            },
            //加载成绩列表
            getExaminationListData(){
                GetExaminationList(this.pageSize,this.page,this.type,this.$store.state.user.category.id).then(res=>{

                    this.records=res.data
                    this.total=res.total;

                })
            },
            //切换类型
            onTabChange(index){

                if(this.active===index){
                    return
                }
                this.active=index;
                this.type=this.tabs[index].type;
                this.page=1;
                this.getExaminationData();
                this.getExaminationListData();
            },
            //分页
            handelChange(val){

                this.page=val;
                this.getExaminationListData();
            }
        },
        beforeDestroy(){
            //解绑
            this.bus.$off(this.ON_CATEGORY_CHANGE)
        }
    }
</script>

<style lang="less" scoped>

    .transcriptList{

        background-color: #F3F4F6;

        .box{
            width: 1200px;
            margin: 20px auto;

            .nav{
                .title{
                    margin-top: 24px;
                    font-size: 34px;

                }
                .desc{
                    color: rgba(60, 60, 67, 0.60);
                    font-size: 14px;
                }
            }

            .content{
                margin-top: 30px;
                background: #FFFFFF;
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                padding: 20px;
                .count{
                    width: 100%;
                    height: 147px;
                    background: linear-gradient(0deg, #EFF5FF 0%, #DCEAFF 11%, #E9F2FF 48%, #F7FCFF 100%);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;

                    .tab{
                        display: flex;
                        margin: 15px 18px 15px 18px;
                        cursor: pointer;

                        &:first-child{
                            margin-left: 0;
                        }

                        .tab-item{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-left: 10px;
                        }

                        .line{

                            height: 5px;
                            width: 15px;
                            border-radius: 5px;
                            display: none;
                            background-color: #3B89FF;

                        }
                        .active {
                            display: block;
                        }
                    }

                    .quest-count{
                        width: 100%;
                        height: 77px;
                        background-color: #fff;
                        border-radius: 4px;
                        margin-left: 16px;
                        display: flex;

                        flex-direction: column;
                        .value{
                            margin-top: 7px;
                            font-size: 34px;
                            font-weight: 500;
                            margin-left: 10px;
                            flex: 1;
                        }
                        .right{

                            margin-right: 10px;
                            color: rgba(60,60,67,0.6);
                            cursor: pointer;
                        }
                        .desc{
                            margin-left: 10px;
                            font-size: 13px;
                            color: rgba(60,60,67,0.6);
                        }

                    }
                    .rate{
                        width: 100%;
                        background: #F2FFF7;
                        height: 77px;
                        border: 0px solid #00C94C;
                        border-radius: 4px;
                        margin-left: 28px;
                        margin-right: 16px;
                        .value{
                            margin-top: 7px;
                            font-size: 34px;
                            font-weight: 500;
                            margin-left: 10px;
                            color: #00C94C;

                        }
                        .desc{
                            margin-left: 10px;
                            font-size: 13px;
                            color: rgba(60,60,67,0.6);
                        }
                    }
                }

                .table-title{
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    .title{
                        font-size: 17px;
                        font-weight: bold;
                    }
                    .desc{
                        margin-left: 10px;
                        font-size: 13px;
                        color: rgba(60,60,67,0.6);
                    }
                }

                .page{
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                }
            }
        }
    }

</style>
